import { Box } from "@mui/material";
import {
  CelebrationsTile,
  CEMKeyMeasures,
  CateringOrdersTile,
  CurrentHourTile,
  HourlySales,
  LaborProductivityTile,
  MTDSalesActivityTile,
  SalesActivityTile,
  SpeedOfServiceTile,
} from "..";
import {
  CelebrationsTile as ICelebrationsTile,
  CEMKeyMeasuresTile,
  CurrentHourSalesActivityTile as ICurrentHourSalesActivityTile,
  DataTile,
  DataTileTypes,
  HourlyTile,
  LaborProductivityTile as ILaborProductivityTile,
  SalesActivityTile as ISalesActivityTile,
  MTD_SalesTile,
  SpeedOfServiceTile as ISpeedOfServiceTile,
  Tile,
  CateringTile,
} from "../../../models";
import Facsimile from "../Facsimile/Facsimile";
interface TileDataLayoutProps {
  tile: Tile & DataTile;
}
const TileDataLayout = ({ tile }: TileDataLayoutProps) => {
  function dataTileRouter() {
    switch (tile?.dataTileType) {
      case DataTileTypes.CATERING:
        return <CateringOrdersTile tile={tile as CateringTile} />;
      case DataTileTypes.SALES_ACTIVITY:
        return <SalesActivityTile tile={tile as ISalesActivityTile} />;
      case DataTileTypes.MTD_SALES:
        return <MTDSalesActivityTile tile={tile as MTD_SalesTile} />;
      case DataTileTypes.LABOR_PRODUCTIVITY:
        return <LaborProductivityTile tile={tile as ILaborProductivityTile} />;
      case DataTileTypes.CEM_KEY_MEASURES:
        return <CEMKeyMeasures tile={tile as CEMKeyMeasuresTile} />;
      case DataTileTypes.SPEED_OF_SERVICE:
        return <SpeedOfServiceTile tile={tile as ISpeedOfServiceTile} />;
      case DataTileTypes.HOURLY:
        return <HourlySales tile={tile as HourlyTile} />;
      case DataTileTypes.CELEBRATIONS:
        return <CelebrationsTile tile={tile as ICelebrationsTile} />;
      case DataTileTypes.CURRENT_HOUR_SALES_ACTIVITY:
        return <CurrentHourTile tile={tile as ICurrentHourSalesActivityTile} />;
      default:
        return <Facsimile type={`${tile?.dataTileType}`} />;
    }
  }

  return (
    <Box className={"w-full h-full relative flex flex-col justify-center"}>
      {dataTileRouter()}
    </Box>
  );
};

export default TileDataLayout;
