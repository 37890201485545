import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  SOSTileSettings,
  SpeedOfServiceTile as ISpeedOfServiceTile,
} from "../../../models";
import Archetype from "../Archetype/Archetype";
import { SpeedOfServiceView } from "./_components";

const defaultSettings: SOSTileSettings = {
  driveThru: false,
  frontCounter: false,
  curbside: false,
};

interface SpeedOfServiceTileProps {
  tile: ISpeedOfServiceTile;
}
const SpeedOfServiceTile = ({
  tile: { context, settings },
}: SpeedOfServiceTileProps) => {
  const [hasError, setHasError] = useState(false);
  const [contextSettings, setContextSettings] =
    useState<SOSTileSettings>(defaultSettings);

  useEffect(() => {
    const tileSettings: SOSTileSettings = {
      ...defaultSettings,
      ...settings,
    };

    const hasAtLeastOneActiveSetting: boolean = Object.values(
      tileSettings,
    ).reduce((acc, x) => acc || x);

    setHasError(!hasAtLeastOneActiveSetting);
    setContextSettings(tileSettings);

    console.debug("SpeedOfServiceTile", context);
  }, []);

  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.SpeedOfService.title")}
        startAdornment={"/speed-of-service-icon.svg"}
        error={hasError}
      >
        {/* Slide 1 */}
        {contextSettings.driveThru && context.driveThru && (
          <SpeedOfServiceView
            label={`${t("tiles.SpeedOfService.driveThru.label")}: ${
              context.driveThru.timeStamp
            }`}
            time={context.driveThru.time}
            timeLabel={t("tiles.SpeedOfService.driveThru.timeLabel")}
            count={context.driveThru.count}
            countLabel={t("tiles.SpeedOfService.driveThru.countLabel")}
          />
        )}

        {/* Slide 2 */}
        {contextSettings.frontCounter && context.frontCounter && (
          <SpeedOfServiceView
            label={`${t("tiles.SpeedOfService.frontCounter.label")}: ${
              context.frontCounter.timeStamp
            }`}
            time={context.frontCounter.time}
            timeLabel={t("tiles.SpeedOfService.frontCounter.timeLabel")}
            count={context.frontCounter.count}
            countLabel={t("tiles.SpeedOfService.frontCounter.countLabel")}
          />
        )}

        {/* Slide 3 */}
        {contextSettings.curbside && context.curbside && (
          <SpeedOfServiceView
            label={`${t("tiles.SpeedOfService.curbside.label")}: ${
              context.curbside.timeStamp
            }`}
            time={context.curbside.time}
            timeLabel={t("tiles.SpeedOfService.curbside.timeLabel")}
            count={context.curbside.count}
            countLabel={t("tiles.SpeedOfService.curbside.countLabel")}
          />
        )}
      </Archetype>
    </Box>
  );
};

export default SpeedOfServiceTile;
