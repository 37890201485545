import { SxProps } from "@mui/material/styles";
import { Paper } from "@mui/material";
import React from "react";

export interface TextStepperProps {
  sx?: SxProps;
  activeStep: number;
  steps: number;
}

const TextStepper = ({ sx, activeStep, steps }: TextStepperProps) => {
  return (
    <Paper
      square
      elevation={0}
      className="font-apercu font-medium text-[16px] text-right"
      sx={{
        bottom: 0,
        ...sx,
      }}
    >
      <React.Fragment>
        {activeStep + 1} of {steps}
      </React.Fragment>
    </Paper>
  );
};

export default TextStepper;
