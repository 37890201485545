import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CircularProgress } from "../..";
import { CEMKeyMeasuresTile } from "../../../models";
import Archetype from "../Archetype/Archetype";

const defaultSetings: CEMKeyMeasuresTile["settings"] = {
  attentiveAndCourteous: false,
  cleanliness: false,
  easeOfOrderPlacing: false,
  fastService: false,
  orderAccuracy: false,
  overallSatisfaction: false,
  portionSize: false,
  tasteOfFood: false,
  winningHeartsEveryDay: false,
};

interface Survey {
  score: number;
  tag: string;
}
interface CEMKeyMeasuresProps {
  tile: CEMKeyMeasuresTile;
}
const CEMKeyMeasures = ({ tile }: CEMKeyMeasuresProps) => {
  const [hasError, setHasError] = useState(false);
  const [activeSurveys, setActiveSurveys] = useState<Array<Survey[]>>([]);

  useEffect(() => {
    const context: Partial<typeof tile.context> = { ...tile.context };
    delete context.surveyCount;
    delete context.lastRefreshedDate;

    const settings: { [key: string]: boolean } = {
      ...defaultSetings,
      ...tile.settings,
    };
    const keyMap: { [key: string]: string } = {
      "attentive.and.courteous.employees": "attentiveAndCourteous",
      cleanliness: "cleanliness",
      "ease.of.placing.order": "easeOfOrderPlacing",
      "fast.service": "fastService",
      "order.accuracy": "orderAccuracy",
      osat: "overallSatisfaction",
      "portion.size": "portionSize",
      "taste.of.food": "tasteOfFood",
      whed: "winningHeartsEveryDay",
    };

    const surveys = Object.values(context)
      .map((survey) => {
        return typeof survey === "object"
          ? { ...survey, visible: settings[keyMap[survey.tag]] }
          : null;
      })
      .filter((survey) => survey && survey.visible);

    // If there are no surveys to display, they need to enable some...
    if (surveys.length === 0) return setHasError(true);

    // Figmas show surveys in pairs, so we need to group them...
    const surveyOrder: Array<Survey[]> = [];
    for (let index = 0; index < surveys.length; index += 2) {
      surveyOrder.push([
        surveys[index] as Survey,
        surveys[index + 1] as Survey,
      ]);
    }

    setActiveSurveys(surveyOrder);
  }, [tile]);

  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t(`tiles.CEMKeyMeasures.title`)}
        startAdornment="/CEM-adorn.svg"
        endAdornment={t(`tiles.CEMKeyMeasures.timePeriod`)}
        error={hasError}
      >
        {activeSurveys.map(([survey1, survey2], index: number) => (
          <Box
            key={index}
            className="relative w-full flex items-center justify-center top-2"
          >
            {survey1 && (
              <Box className="w-full flex flex-col items-center text-center scale-95">
                <CircularProgress value={(survey1?.score || 0) * 100} />
                <Typography variant="caption" className="truncate-text w-full">
                  {t(`tiles.CEMKeyMeasures.${survey1.tag}`)}
                </Typography>
              </Box>
            )}
            {survey2 && (
              <Box className="w-full flex flex-col items-center text-center scale-95">
                <CircularProgress value={(survey2?.score || 0) * 100} />
                <Typography variant="caption" className="truncate-text w-full">
                  {t(`tiles.CEMKeyMeasures.${survey2.tag}`)}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Archetype>
    </Box>
  );
};

export default CEMKeyMeasures;
