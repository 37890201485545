/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useReducer } from "react";
import { Tile } from "../..";
import { DataTile, Tile as ITile } from "../../../models";

enum SlideNineTileDashboardActionType {
  DROP = "DROP",
  DELETE = "DELETE",
  SET = "SET",
}
interface SlideNineTileDashboardAction {
  type: SlideNineTileDashboardActionType;
  payload: any;
}
interface SlideNineTileDashboardState {
  list: Array<(ITile & DataTile) | null>;
}
const initialState: SlideNineTileDashboardState = {
  list: Array.from({ length: 9 }, () => null),
};
function reducer(
  state: SlideNineTileDashboardState,
  action: SlideNineTileDashboardAction,
) {
  switch (action.type) {
    case SlideNineTileDashboardActionType.DROP:
      return {
        ...state,
        list: state.list.map((tile, i) =>
          i === action.payload.index ? action.payload?.newTile : tile,
        ),
      };
    case SlideNineTileDashboardActionType.DELETE:
      return {
        ...state,
        list: state.list.map((tile, i) =>
          i === action.payload.index ? null : tile,
        ),
      };
    case SlideNineTileDashboardActionType.SET:
      return {
        ...state,
        list: action.payload,
      };
    default:
      throw new Error();
  }
}

interface SlideNineTileDashboardProps {
  tiles: Array<ITile | null>;
}
const SlideNineTileDashboard = ({ tiles }: SlideNineTileDashboardProps) => {
  const [{ list }, dispatch] = useReducer(reducer, {
    ...initialState,
    list: tiles,
  });

  useEffect(() => {
    const tileData = [...list];
    while (tileData.length < 9) {
      tileData.push(null);
    }
    dispatch({
      type: SlideNineTileDashboardActionType.SET,
      payload: tileData,
    });
  }, [tiles]);

  return (
    <Box className="full p-1 grid grid-cols-3 grid-rows-3 gap-x-8 gap-y-6">
      {list.map((tile: (ITile & DataTile) | null, i: number) => (
        <Box
          key={`${i}`}
          className={`w-full h-full rounded-md flex flex-col justify-center overflow-hidden bg-transparent ${
            tile ? "shadow-lg bg-white" : "bg-transparent"
          }`}
        >
          {tile?.dataTileType && (
            <Box className="relative w-full h-full flex justify-center">
              <Tile tile={tile as ITile} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default observer(SlideNineTileDashboard);
