import { ArrowUpward } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import {
  TileArchetypeView,
  TileArchetypeCenterContent,
} from "../../../Archetype/Archetype";
import { ReactNode } from "react";

interface TodaysSalesProps {
  label: string;
  icon: string;
  quantity: ReactNode;
  chip: {
    label: ReactNode;
    direction: boolean;
  };
}
const SalesView = ({ label, icon, quantity, chip }: TodaysSalesProps) => {
  return (
    <TileArchetypeView label={label}>
      <TileArchetypeCenterContent sx={{ left: "-20px" }}>
        <Box
          component="img"
          src={icon}
          className="relative scale-[65%] -right-3"
        />
        <Box className="relative flex flex-col right-1 gap-1">
          <Box
            className="font-apercu font-bold text-[34px] text-left"
            sx={{ lineHeight: "38px" }}
          >
            {quantity}
          </Box>
          <Chip
            color={chip.direction ? "success" : "error"}
            size="small"
            label={
              <Box className="flex gap-1 p-[2px] items-center justify-center">
                <ArrowUpward
                  sx={{
                    fontSize: "10px",
                    stroke: "white",
                    strokeWidth: 4,
                    transform: `rotate(${chip.direction ? 0 : 180}deg)`,
                  }}
                />
                <Box className="font-apercu font-bold text-[11px]">
                  {chip.label}
                </Box>
              </Box>
            }
            sx={{
              height: "fit-content",
            }}
          />
        </Box>
      </TileArchetypeCenterContent>
    </TileArchetypeView>
  );
};
export default SalesView;
