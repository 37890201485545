import { Box } from "@mui/material";
import { t } from "i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { HourlyTile, SECONDARY_COLOR } from "../../../models";
import Archetype from "../Archetype/Archetype";

interface HourlySalesTileProps {
  tile: HourlyTile;
}
const HourlySalesTile = ({ tile: { context } }: HourlySalesTileProps) => {
  useEffect(() => {
    console.debug("HourlySalesTile", context);
  }, []);

  const hasSales = (currentSales: string, previousSales: string) => {
    const numberCurrentSales = Number(currentSales.replace(/[^0-9.-]+/g, ""));
    const numberPreviousSales = Number(previousSales.replace(/[^0-9.-]+/g, ""));
    if (numberCurrentSales > 0 && numberPreviousSales > 0) {
      return true;
    } else {
      return false;
    }
  };

  const percentChangeArrow = (currentSales: string, previousSales: string) => {
    const numberCurrentSales = Number(currentSales.replace(/[^0-9.-]+/g, ""));
    const numberPreviousSales = Number(previousSales.replace(/[^0-9.-]+/g, ""));

    if (numberCurrentSales > numberPreviousSales) {
      return "/arrow-big-down-filled.svg";
    } else {
      return "/arrow-big-up-filled.svg";
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: SECONDARY_COLOR,
      color: theme.palette.common.white,
      fontSize: 10,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 10,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.HourlySales.title")}
        startAdornment={"/hourly-sales-icon.svg"}
      >
        <TableContainer component={Paper} sx={{ marginTop: 1 }}>
          <Table size="small" aria-label="hourly-table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ minWidth: 50 }} align="center">
                  Time
                </StyledTableCell>
                <StyledTableCell align="center">Today</StyledTableCell>
                <StyledTableCell
                  sx={{ minWidth: 50, paddingRight: 0, paddingLeft: 0 }}
                  align="center"
                >
                  Last Year
                </StyledTableCell>
                <StyledTableCell
                  sx={{ minWidth: 50, paddingLeft: 0 }}
                  align="center"
                >
                  % Change
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {context.salesHours.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    {row.shortTime}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.currentSales}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.previousSales}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {hasSales(row.currentSales, row.previousSales) ? (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box
                          component="img"
                          src={percentChangeArrow(
                            row.currentSales,
                            row.previousSales,
                          )}
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: 15,
                            paddingRight: 1,
                          }}
                        />
                        <Box>{row.changePercent}</Box>
                      </Box>
                    ) : (
                      row.changePercent
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Archetype>
    </Box>
  );
};

export default HourlySalesTile;
