import { Box } from "@mui/material";
import { t } from "i18next";
import { MTD_SalesTile } from "../../../models";
import Archetype from "../Archetype/Archetype";
import { SalesView } from "./_components";

interface MTDSalesActivityTileProps {
  tile: MTD_SalesTile;
}
const MTDSalesActivityTile = ({
  tile: { context },
}: MTDSalesActivityTileProps) => {
  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.MTDSalesActivity.title")}
        startAdornment={"/mtd-sales-icon.svg"}
      >
        {/* Slide 1 */}
        {context.sales && (
          <SalesView
            label={t("tiles.MTDSalesActivity.sales.label")}
            icon={"/money.svg"}
            quantity={context.sales.totalAmount}
            chip={{
              label: `${context.sales.changePercent} ${t(
                "tiles.MTDSalesActivity.sales.duration",
              )}`,
              direction: context.sales.changePercent.startsWith("+"),
            }}
            showChip={context.sales.hasPriorPeriodData}
          />
        )}

        {/* Slide 2 */}
        {context.transactions && (
          <SalesView
            label={t("tiles.MTDSalesActivity.transactions.label")}
            icon={"/carry-out.svg"}
            quantity={context.transactions.totalAmount}
            chip={{
              label: `${context.transactions.changePercent} ${t(
                "tiles.MTDSalesActivity.transactions.duration",
              )}`,
              direction: context.transactions.changePercent.startsWith("+"),
            }}
            showChip={context.transactions.hasPriorPeriodData}
          />
        )}

        {/* Slide 3 */}
        {context.checkAverages && (
          <SalesView
            label={t("tiles.MTDSalesActivity.checkAverages.label")}
            icon={"/check-average.svg"}
            quantity={context.checkAverages.totalAmount}
            chip={{
              label: `${context.checkAverages.changePercent} ${t(
                "tiles.MTDSalesActivity.checkAverages.duration",
              )}`,
              direction: context.checkAverages.changePercent.startsWith("+"),
            }}
            showChip={context.checkAverages.hasPriorPeriodData}
          />
        )}
      </Archetype>
    </Box>
  );
};

export default MTDSalesActivityTile;
