import { Box, CircularProgress as CP, Typography } from "@mui/material";

interface CircularProgressProps {
  value: number;
}
const CircularProgress = ({ value }: CircularProgressProps) => {
  return (
    <Box className={`relative w-[75px] h-[75px] scale-[85%]`}>
      <Box className="absolute w-full h-full flex items-center justify-center">
        <Box
          className="w-[60px] h-[60px]"
          sx={{ borderRadius: "50%", backgroundColor: "rgba(93, 55, 84, 1)" }}
        />
      </Box>
      <Box className="absolute w-full h-full">
        <CP
          variant="determinate"
          value={value}
          size={75}
          thickness={5}
          sx={{ color: "rgb(174,155,169)" }}
        />
      </Box>
      <Box className="absolute w-full h-full flex items-center justify-center">
        <Typography sx={{ color: "white" }}>
          <strong>{value}%</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgress;
