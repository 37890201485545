import { Box } from "@mui/material";
import { Typography } from "cfa-react-components";
import { useState } from "react";

interface CateringViewProps {
  status: string;
  customerName: string;
  pickupDateTime: string;
  totalAmount: number;
  destinationDescription: string;
  destinationCode: string;
  fireStatus: boolean;
}
const CateringView = ({
  status,
  customerName,
  pickupDateTime,
  totalAmount,
  destinationDescription,
  destinationCode,
  fireStatus,
}: CateringViewProps) => {
  const [report] = useState<{ text: string; icon: string }>(() => {
    if (status === "Finalized") {
      return {
        text: destinationCode === "Delivery" ? "Delivered" : "Picked Up",
        icon: "/discount-check.svg",
      };
    } else {
      if (fireStatus) return { text: "Prep", icon: "/tools-kitchen.svg" };
      return { text: "Ordered", icon: "/receipt.svg" };
    }
  });

  return (
    <Box className="flex justify-center gap-2 font-apercu">
      <Box className="flex flex-col text-left">
        <Typography className="font-bold max-w-[150px] truncate">
          {customerName}
        </Typography>
        <Typography variant="caption1" className="text-sm">
          {new Date(pickupDateTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}{" "}
          {destinationDescription}
        </Typography>
        <Typography variant="caption1" className="text-sm">
          ${Number(totalAmount).toFixed(2)}
        </Typography>
      </Box>
      <Box className="flex flex-col gap-1 items-center justify-center scale-90">
        <Box component="img" src={report.icon} className="h-[30px] w-[30px]" />
        <Typography className="font-bold">{report.text}</Typography>
      </Box>
    </Box>
  );
};

export default CateringView;
