import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  CurrentHourSalesActivityTileSettings,
  CurrentHourSalesActivityTile as ICurrentHourTile,
} from "../../../models";
import Archetype from "../Archetype/Archetype";
import { CurrentHourView } from "./_components";

const defaultSettings: CurrentHourSalesActivityTileSettings = {
  curbside: false,
  dineIn: false,
  catering: false,
  driveThru: false,
  onDemand: false,
  toGo: false,
  fullService: false,
};

interface CurrentHourTileProps {
  tile: ICurrentHourTile;
}
const CurrentHourTile = ({
  tile: { context, settings },
}: CurrentHourTileProps) => {
  const [hasError, setHasError] = useState(false);
  const [contextSettings, setContextSettings] =
    useState<CurrentHourSalesActivityTileSettings>(defaultSettings);

  useEffect(() => {
    const tileSettings: CurrentHourSalesActivityTileSettings = {
      ...defaultSettings,
      ...settings,
    };

    const hasAtLeastOneActiveSetting: boolean = Object.values(
      tileSettings,
    ).reduce((acc, x) => acc || x);

    setHasError(!hasAtLeastOneActiveSetting);
    setContextSettings(tileSettings);
  }, []);

  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.CurrentHourSalesActivity.title")}
        startAdornment={"/current_hour/icon.svg"}
        error={hasError}
      >
        {contextSettings.driveThru && context.driveThru && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.driveThru.label")}
            type="DRIVE_THRU"
            totalSales={context.driveThru.sales}
            transactions={context.driveThru.transactions}
            checkAverage={context.driveThru.checkAverage}
          />
        )}

        {contextSettings.dineIn && context.dineIn && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.dineIn.label")}
            type="DINE_IN"
            totalSales={context.dineIn.sales}
            transactions={context.dineIn.transactions}
            checkAverage={context.dineIn.checkAverage}
          />
        )}
        {contextSettings.toGo && context.toGo && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.toGo.label")}
            type="TO_GO"
            totalSales={context.toGo.sales}
            transactions={context.toGo.transactions}
            checkAverage={context.toGo.checkAverage}
          />
        )}
        {contextSettings.catering && context.catering && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.catering.label")}
            type="CATERING"
            totalSales={context.catering.sales}
            transactions={context.catering.transactions}
            checkAverage={context.catering.checkAverage}
          />
        )}
        {contextSettings.onDemand && context.onDemand && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.onDemand.label")}
            type="ON_DEMAND"
            totalSales={context.onDemand.sales}
            transactions={context.onDemand.transactions}
            checkAverage={context.onDemand.checkAverage}
          />
        )}
        {contextSettings.curbside && context.curbside && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.curbside.label")}
            type="CURBSIDE"
            totalSales={context.curbside.sales}
            transactions={context.curbside.transactions}
            checkAverage={context.curbside.checkAverage}
          />
        )}
        {contextSettings.fullService && context.fullService && (
          <CurrentHourView
            label={t("tiles.CurrentHourSalesActivity.fullService.label")}
            type="FULL_SERVICE"
            totalSales={context.fullService.sales}
            transactions={context.fullService.transactions}
            checkAverage={context.fullService.checkAverage}
          />
        )}
      </Archetype>
    </Box>
  );
};

export default CurrentHourTile;
