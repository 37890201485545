import { v4 as uuid } from "uuid";
import {
  CEMTileSetting,
  DataTileSubType,
  ObjectTypes,
  SOSTileSetting,
  TextTile,
  Tile,
  TileType,
} from ".";

export const TileTypes: { [key in TileType]: TileType } = {
  DATA: "DATA",
  IMAGE: "IMAGE",
  TEXT: "TEXT",
  VIDEO: "VIDEO",
};

export const DataTileTypes: { [key in DataTileSubType]: DataTileSubType } = {
  CATERING: "CATERING",
  CELEBRATIONS: "CELEBRATIONS",
  CEM_KEY_MEASURES: "CEM_KEY_MEASURES",
  CEM_COMMENTS: "CEM_COMMENTS",
  CURRENT_HOUR_SALES_ACTIVITY: "CURRENT_HOUR_SALES_ACTIVITY",
  HOURLY: "HOURLY",
  LABOR_PRODUCTIVITY: "LABOR_PRODUCTIVITY",
  MTD_SALES: "MTD_SALES",
  SALES_ACTIVITY: "SALES_ACTIVITY",
  SPEED_OF_SERVICE: "SPEED_OF_SERVICE",
};

export const CEMTileSettingTypes: { [key: string]: CEMTileSetting } = {
  ATTENTIVE_AND_COURTEOUS: "attentiveAndCourteous",
  CLEANINESS: "cleanliness",
  EASE_OF_ORDER_PLACING: "easeOfOrderPlacing",
  FAST_SERVICE: "fastService",
  ORDER_ACCURANCY: "orderAccuracy",
  OVERALL_SATISFACTION: "overallSatisfaction",
  PORTION_SIZE: "portionSize",
  TASTE_OF_FOOD: "tasteOfFood",
};

export const SOSTileSettingTypes: { [key: string]: SOSTileSetting } = {
  DRIVE_THRU: "driveThru",
  FRONT_COUNTER: "frontCounter",
  CURBSIDE: "curbside",
};

export interface TileOptionsProp {
  key: CEMTileSetting | SOSTileSetting;
  label: string;
}

/**
 * @param { Partial<Tile> } tile If you have existing tile data, you can pass it in here to create a new tile with the same data.
 * @returns { Tile & T }
 * @example
 *    const tile = createTile<TextTile>({ tileType: TileType.TEXT });
 *    const tile = createTile<TextTile>({ name: "My Tile", TileType.TEXT });
 *    const test = createTile<ImageTile>({ tileType: TileType.IMAGE });
 */
export const createTile = <T>(tile?: Partial<Tile>): Tile & T => {
  return {
    locationId: ``,
    name: `${uuid()}`,
    objectType: ObjectTypes.TILE,
    tileType: TileTypes.DATA,
    ...tile,
  } as Tile & T;
};

/**
 * @param { Tile } tile Tile to return contextual data for
 * @returns context object properly structure and ready to use
 * @example
 *    const tile = createTile<TextTile>({ tileType: TileType.TEXT });
 *    const context = getTextTileContext(tile);
 */
export const getTextTileContext = (tile: Tile) => {
  try {
    if (!tile?.context)
      Object.assign(tile, { context: { styles: "", title: "", body: "" } });
    if (typeof tile.context === "string") {
      const data = JSON.parse(tile.context);
      // check if required properties exist
      if (!data?.styles) data.styles = "";
      if (!data?.title) data.title = "";
      if (!data?.body) data.body = "";
      return data;
    }
    return tile.context;
  } catch {
    console.error("Error getting context for text tile");
    return {
      styles: "",
      title: "",
      body: "",
    };
  }
};

/**
 * @description Helper method to create a new text tile
 * @param { TextTile } tile partial data to override any defaults set by helper
 * @returns { TextTile } Tile that has base required fields
 */
export function createNewTextTile(
  tile?: Partial<TextTile> & { locationId: string },
): Tile {
  return {
    name: `${uuid()}`,
    description: "",
    objectType: ObjectTypes.TILE,
    tileType: TileTypes.TEXT,
    content: "",
    locationId: tile?.locationId || "",
    context: {
      styles: {},
      title: {
        entityMap: [],
        blocks: [],
      },
      body: {
        entityMap: [],
        blocks: [],
      },
      ...tile?.context,
    },
    style: tile?.style || "",
    ...tile,
  } as Tile;
}
