import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getSlideById } from "../../api/slides.api";
import { NetworkError, Slide } from "../../components";
import { Slide as ISlide } from "../../models";

const SlidePage = () => {
  const ref = useRef<HTMLElement>(null);
  const [width, setWidth] = useState<number>(1104);
  const { id } = useParams();
  const { search } = useLocation();
  const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF");
  const [error, setError] = useState<string[] | null>(null);
  const [slideData, setSlideData] = useState<ISlide | null>(null);

  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    const backgroundColor = new URLSearchParams(search).get("bgColor");
    if (backgroundColor) setBackgroundColor(backgroundColor);
    if (token) localStorage.setItem("token", token);
    getSlideById(`${id}`)
      .then((response) => {
        setSlideData(response.data);
      })
      .catch((error) =>
        setError([error?.message, error?.response?.statusText]),
      );
  }, []);

  useEffect(() => console.error(error), [error]);

  useEffect(() => {
    console.log("width", ref?.current?.clientWidth);
    setWidth(ref?.current?.clientWidth || 1104);
  }, [useMemo(() => ref?.current?.clientWidth, [ref?.current?.clientWidth])]);

  return (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: error ? "#FFFFFF" : backgroundColor,
      }}
    >
      {error && <NetworkError message={error} />}
      {slideData && <Slide slide={slideData} width={width} />}
    </Box>
  );
};

export default SlidePage;
