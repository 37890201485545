import { Box, Divider } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Archetype } from "..";
import { CateringTile } from "../../../models";
import Carousel from "../../Carousel/Carousel";
import { TileArchetypeView } from "../Archetype/Archetype";
import CateringView from "./_components/CateringView/CateringView";

interface CateringOrdersTileProps {
  tile: CateringTile;
}
const CateringOrdersTile = ({ tile }: CateringOrdersTileProps) => {
  const [orders, setOrders] = useState<typeof tile.context.deferredOrders>([]);

  useEffect(() => {
    /**
     * 1st: Orders must be in either "Stored" or "Finalized" status
     * 2nd: If the order has status "Finalized", only orders with a pickupDate > ten minutes ago or in the future is dsiplayed
     * 3rd: sort by status, then pikcup time, then customer name
     */
    console.log("CateringOrdersTile", tile.context.deferredOrders);
    setOrders(
      tile.context.deferredOrders
        .filter((order) => ["Stored", "Finalized"].includes(order.status))
        // .filter((order) => {
        //   if (
        //     order.status === "Finalized" &&
        //     new Date(order?.pickupDateTime) >
        //       new Date(Date.now() + 10 * 60 * 1000)
        //   )
        //     return false;
        // })
        .sort((a, b) => {
          if (a.status === b.status) {
            if (a.pickupDateTime === b.pickupDateTime)
              return a.customerName.localeCompare(b.customerName);
            return a.pickupDateTime.localeCompare(b.pickupDateTime);
          }
          return a.status.localeCompare(b.status);
        }),
    );
    console.debug("Orders", orders);
  }, []);

  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={`${t("tiles.CateringOrders.title")}`}
        startAdornment="/catering-icon.svg"
      >
        <TileArchetypeView
          label={`${t("tiles.CateringOrders.totalAmount")} $${Number(
            tile.context.totalOrders,
          ).toFixed(2)}`}
        >
          <Divider className="absolute w-full left-0 py-1" />
          {orders.length > 0 && (
            <Carousel className="pt-5 flex">
              {orders.map((order, index) => (
                <CateringView
                  key={index}
                  status={order.status}
                  customerName={order.customerName}
                  pickupDateTime={order.pickupDateTime}
                  totalAmount={order.totalAmount}
                  destinationDescription={order.destinationDesc}
                  destinationCode={order.destinationCode}
                  fireStatus={order.fireStatus}
                />
              ))}
            </Carousel>
          )}
        </TileArchetypeView>
      </Archetype>
    </Box>
  );
};

export default CateringOrdersTile;
