import { Box } from "@mui/material";
import { t } from "i18next";
import {
  TileArchetypeView,
  TileArchetypeCenterContent,
} from "../../../Archetype/Archetype";

interface ProductivityProps {
  label: string;
  productivity: string;
  percentageOfSales: string;
}
const ProductivityView = ({
  label,
  productivity,
  percentageOfSales,
}: ProductivityProps) => {
  return (
    <TileArchetypeView label={label}>
      <TileArchetypeCenterContent className="gap-4">
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box
            className="font-apercu font-bold text-[32px] text-left"
            sx={{ lineHeight: "40px" }}
          >
            {productivity}
          </Box>
          <Box
            className="font-apercu font-medium text-[11px] text-left"
            sx={{ lineHeight: "12px" }}
          >
            {t("tiles.LaborProductivity.productivityLabel")}
          </Box>
        </Box>
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box
            className="font-apercu font-bold text-[32px] text-left"
            sx={{ lineHeight: "40px" }}
          >
            {percentageOfSales}
          </Box>
          <Box
            className="font-apercu font-medium text-[11px] text-left"
            sx={{ lineHeight: "12px" }}
          >
            {t("tiles.LaborProductivity.percentOfSalesLabel")}
          </Box>
        </Box>
      </TileArchetypeCenterContent>
    </TileArchetypeView>
  );
};
export default ProductivityView;
