import { Box } from "@mui/material";
import { useState } from "react";
import { Image, ImageTile } from "../../../models";

interface TileImageLayoutProps {
  image: ImageTile["src"];
}
const TileImageLayout = ({ image }: TileImageLayoutProps) => {
  const [tileImage] = useState<Image>(image as Image);

  return (
    <Box className="h-full w-full flex flex-col justify-center items-center align-middle">
      {tileImage && (
        <Box
          component="img"
          src={`${tileImage?.fetchUrl}`}
          className="w-full h-full object-cover"
        />
      )}
    </Box>
  );
};

export default TileImageLayout;
