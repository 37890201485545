import {
  FullScreenFeature,
  MultiTileDashboard,
  ObjectTypes,
  Slide,
  SlideRecommendation,
  SlideSharedLocation,
  createHistoriedItem,
  createNewTextTile,
} from ".";

export const SLIDE_ASPECT_RATIO = "1.8/1";

export const SlideRecommendations: { [key: string]: SlideRecommendation } = {
  CUSTOM: "Custom",
  SUGGESTED: "Suggested",
};

export const FULLSCREEN_FEATURES: { [key: string]: FullScreenFeature } = {
  TEXT: "FULLSCREEN_TEXT",
  VIDEO: "FULLSCREEN_VIDEO",
  IMAGE: "FULLSCREEN_IMAGE",
  IMAGE_WITH_TEXT: "SPLITSCREEN_IMAGE_TEXT",
};

export const MULTI_TILE_DASHBOARD: { [key: string]: MultiTileDashboard } = {
  FOUR_TILE_DASHBOARD: "DASHBOARD_FOUR_TILE",
  NINE_TILE_DASHBOARD: "DASHBOARD_NINE_TILE",
  FEATURED_TILE_DASHBOARD: "DASHBOARD_FEATURED_TILE",
};

export const parseSlideMetaData = (
  slide: Slide & { sharedLocations: Array<SlideSharedLocation> },
) => {
  const now = new Date();
  const lastEdited = new Date(slide?.lastEdited?.date || now);
  const timeDiff = Math.abs(now.getTime() - lastEdited.getTime());
  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const editedString = dayDiff > 1 ? `${dayDiff} days ago` : "Today";
  let sharedString = "";
  if (slide?.sharedLocations?.length > 0) {
    sharedString = [
      "Shared with",
      slide?.sharedLocations?.map((l) => `${l?.locationId} ${l.locationName}`),
    ].join(" ");
  }
  const parts = [
    `${slide.isSuggested ? "Suggested" : "Custom"} Slide`,
    `By ${slide?.created?.user}`,
    `Edited by ${slide?.lastEdited?.user} ${editedString}`,
    slide?.sharedLocations?.length > 0 ? sharedString : "Not Shared",
  ].join(" \xB7 ");
  return parts;
};

export const filterSlides = (slides: Slide[], filters: Array<string>) => {
  if (filters.length === 0) return slides;
  let collection = [...slides];

  for (const filter of filters) {
    switch (filter) {
      case "custom":
        collection = collection.filter((slide) => !slide?.isSuggested);
        break;
      case "suggested":
        collection = collection.filter((slide) => slide.isSuggested);
        break;
      default:
        break;
    }
  }
  return collection;
};

export const createSlide = <T>(slide?: Partial<Slide>): Slide & T => {
  return {
    id: "",
    objectType: ObjectTypes.SLIDE,
    name: "",
    description: "",
    locationId: "",
    isSuggested: false,
    style: {},
    created: createHistoriedItem(),
    lastEdited: createHistoriedItem(),
    ...slide,
  } as Slide & T;
};

export const fullscreenFeaturesTextSlideAdapter = (original: Slide) => {
  const slide: Slide = { ...original };

  if (slide.layoutType !== FULLSCREEN_FEATURES.TEXT)
    throw new Error(`Slide is not correct type: ${slide.layoutType}`);

  if (slide.tiles.length > 1)
    throw new Error(`Slide has incorrect number of tiles! id:${slide.id}`);

  if (slide.tiles.length < 1) {
    slide.tiles.push(createNewTextTile({ locationId: slide.locationId }));
  }

  console.debug("fullscreenFeaturesTextSlideAdapter->done", slide);
  return slide;
};

export const multiTileDashboardNineTileAdatper = (original: Slide) => {
  const slide: Slide = { ...original };

  if (slide.layoutType !== MULTI_TILE_DASHBOARD.NINE_TILE_DASHBOARD)
    throw new Error(`Slide is not correct type: ${slide.layoutType}`);

  if (slide.tiles.length > 9)
    throw new Error(`Slide has incorrect number of tiles! id:${slide.id}`);

  if (slide.tiles.length < 9) {
    console.debug("Incorrect number of tiles, padding with empty tiles");
    while (slide.tiles.length < 9) {
      slide.tiles.push(null);
    }
    console.debug("New slide list", slide.tiles);
  }

  console.debug("multiTileDashboardNineTileAdatper->done", slide);
  return slide;
};

export const slideAdapter = (original: Slide): Slide => {
  try {
    console.groupCollapsed(`SlideAdapter "${original.name}"`);
    console.debug("Original Slide", original);

    const slide = { ...original };

    // Check for slide style property and set default if not present
    if (!slide.style) {
      console.debug("Slide has no style, setting default");
      slide.style = { backgroundColor: "#FFFFFF" };
    }

    switch (slide.layoutType) {
      case FULLSCREEN_FEATURES.TEXT:
        return fullscreenFeaturesTextSlideAdapter(slide);
      case MULTI_TILE_DASHBOARD.NINE_TILE_DASHBOARD:
        return multiTileDashboardNineTileAdatper(slide);
      case MULTI_TILE_DASHBOARD.FOUR_TILE_DASHBOARD:
      case MULTI_TILE_DASHBOARD.FEATURED_TILE_DASHBOARD:
      case FULLSCREEN_FEATURES.VIDEO:
      case FULLSCREEN_FEATURES.IMAGE:
      default:
        return slide;
    }
  } catch (error) {
    console.error(error);
    return original;
  } finally {
    console.groupEnd();
  }
};
