import { Box } from "@mui/material";
import { Tile } from "../../";
import { Tile as ITile } from "../../../models";

interface SlideFullFeaturedImageProps {
  tile: ITile;
}
const SlideFullFeaturedImage = ({ tile }: SlideFullFeaturedImageProps) => {
  return (
    <Box className="w-full h-full absolute left-0 top-0 flex justify-center overflow-hidden rounded-sm">
      <Tile tile={tile} />
    </Box>
  );
};

export default SlideFullFeaturedImage;
