import { Box, Card, CardContent, Container, Typography } from "@mui/material";

interface NetworkErrorProps {
  message?: string[] | null;
}
const NetworkError = ({ message }: NetworkErrorProps) => {
  return (
    <Box className="w-full h-full flex flex-col justify-center">
      <Container maxWidth="sm">
        <Card className="p-10 shadow-xl">
          <CardContent className="flex flex-col justify-center items-center gap-6">
            <Box
              component="img"
              src={"/loading-error.svg"}
              className="h-[250px] w-[250px]"
            />
            <Typography variant="h4">
              <strong>Loading Error</strong>
            </Typography>
            {message?.map((mes) => (
              <Typography key={mes} variant="body1">
                {mes}
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default NetworkError;
