import {
  DraftStyleMap,
  EditorState,
  RawDraftContentState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { CustomColor } from ".";

export const PRIMARY_COLOR = "#DD0031";
export const SECONDARY_COLOR = "#004F71";
export const PRIMARY_WHITE = "#FFFFFF";
export const PRIMARY_RED = "#DD0031";
export const SECONDARY_BLUE = "#004F71";
export const TERTIARY_LIGHT_YELLOW = "#F5E1A4";
export const TERTIARY_YELLOW = "#FFB549";
export const TERTIARY_PINK = "#F8C1B8";
export const TERTIARY_PEACH = "#FA9370";
export const TERTIARY_ORANGE = "#E35205";
export const TERTIARY_GREEN = "#249E6B";
export const TERTIARY_DARK_GREEN = "#00635B";
export const TERTIARY_PURPLE = "#994878";
export const TERTIARY_DARK_PURPLE = "#5D3754";
export const GRAY_7 = "#37424A";

export const RichTextColors = {
  PRIMARY_WHITE,
  PRIMARY_RED,
  SECONDARY_BLUE,
  TERTIARY_LIGHT_YELLOW,
  TERTIARY_YELLOW,
  TERTIARY_PINK,
  TERTIARY_PEACH,
  TERTIARY_ORANGE,
  TERTIARY_GREEN,
  TERTIARY_DARK_GREEN,
  TERTIARY_PURPLE,
  TERTIARY_DARK_PURPLE,
  GRAY_7,
};

export const CustomStyleMap: DraftStyleMap = {
  BLACK: {
    color: RichTextColors.GRAY_7,
  },
  WHITE: {
    color: RichTextColors.PRIMARY_WHITE,
  },
  DARKGREEN: {
    color: RichTextColors.TERTIARY_DARK_GREEN,
  },
  RED: {
    color: RichTextColors.PRIMARY_RED,
  },
  ORANGE: {
    color: RichTextColors.TERTIARY_ORANGE,
  },
  GREEN: {
    color: RichTextColors.TERTIARY_GREEN,
  },
  PRIMARY: {
    color: RichTextColors.PRIMARY_RED,
  },
  SECONDARY: {
    color: RichTextColors.SECONDARY_BLUE,
  },
  TEXT_BODY_1: {
    fontSize: "36px",
  },
  TEXT_BODY_2: {
    fontSize: "32px",
  },
  TEXT_BODY_3: {
    fontSize: "28px",
  },
  TEXT_BODY_4: {
    fontSize: "24px",
  },
  TEXT_BODY_5: {
    fontSize: "20px",
  },
  TEXT_BODY_6: {
    fontSize: "16px",
  },
  LIST_EXTRA_SMALL: {},
  LIST_SMALL: {},
  LIST_MEDIUM: {},
  LIST_LARGE: {},
  LIST_EXTRA_LARGE: {},
};

export const changeTextColor = (editorState: EditorState, color: CustomColor) =>
  RichUtils.toggleInlineStyle(editorState, color);

export const getCurrentBlockStyle = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return blockType;
};

export const currentInlineStyles = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const currentBlock = currentContent.getBlockForKey(startKey);
  const startOffset = selection.getStartOffset();
  const inlineStyles = currentBlock.getInlineStyleAt(startOffset);
  return inlineStyles;
};

export const getEditorContents = (editorState: EditorState) => {
  return convertToRaw(editorState.getCurrentContent());
};

export const setEditorState = (content: RawDraftContentState | string) => {
  try {
    let contentState;
    if (typeof content === "string") contentState = JSON.parse(content);
    contentState = convertFromRaw(contentState);
    return EditorState.createWithContent(contentState);
  } catch (_err) {
    return EditorState.createEmpty();
  }
};

export const customTextColorMap: Record<CustomColor, string> = {
  BLACK: RichTextColors.GRAY_7,
  WHITE: RichTextColors.PRIMARY_WHITE,
  SECONDARY: SECONDARY_COLOR,
  PRIMARY: PRIMARY_COLOR,
};
