import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { RichTextEditor } from "../../";
import { TextTile } from "../../../models";

interface TileTextLayoutProps {
  context: TextTile["context"];
  onContextChange?: (context: TextTile["context"]) => void;
}
const TileTextLayout: FC<TileTextLayoutProps> = ({
  context,
  onContextChange,
}) => {
  const [title, setTitle] = useState(context?.title);
  const [body, setBody] = useState(context?.body);

  useEffect(() => {
    onContextChange?.({
      ...context,
      title,
      body,
    });
  }, [title, body]);

  return (
    <Box className="w-full h-full grid grid-cols-1 grid-rows-6 gap-6">
      <Box component="section" className="row-span-1">
        <Box
          className="full flex flex-col justify-center"
          sx={{
            "& .public-DraftEditor-content": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            },
          }}
        >
          {title && (
            <RichTextEditor
              defaultValue={""}
              rawContentState={title}
              controlGroups={["text-color-picker", "divider", "title"]}
              onChange={setTitle}
              selectable
              noWrap
            />
          )}
        </Box>
      </Box>
      <Box component="section" className="row-span-5">
        {body && (
          <RichTextEditor
            defaultValue={""}
            rawContentState={body}
            controlGroups={[
              "text-color-picker",
              "divider",
              // "heading",
              // "title",
              "text",
              "divider",
              "bold",
              "italic",
              "underline",
              "divider",
              "unordered-list",
              "ordered-list",
            ]}
            onChange={setBody}
            selectable
          />
        )}
      </Box>
    </Box>
  );
};

export default TileTextLayout;
