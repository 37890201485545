import { Box, SxProps } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {
  FULLSCREEN_FEATURES,
  Slide as ISlide,
  MULTI_TILE_DASHBOARD,
  SLIDE_ASPECT_RATIO,
  Tile as TileModel,
} from "../../models";
import {
  SlideFullFeaturedImage,
  SlideFullFeaturedText,
  SlideNineTileDashboard,
} from "../_Slide";

interface SlideProps {
  slide: ISlide;
  width?: number;
  sx?: SxProps;
  editable?: boolean;
  active?: boolean;
  onClick?: () => void;
  onSlideChange?: (slide: ISlide) => void;
  children?: ReactNode;
}
const Slide = ({ slide, width, sx, onClick }: SlideProps) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState({ boundingWidth: 0, boundingHeight: 0 });
  const initialPixelWidth = 1104; // This is the width of the slide in pixels at 100% zoom to scale 1:1
  const [backgroundColor] = useState<{
    backgroundColor: string;
  }>(() => {
    // TODO - MUST REFACTOR THIS!!!!!!!!!!!!!!!!!!
    // Check if style attribute is set correctly
    const defaultColor = { backgroundColor: "#FFFFFF" };
    switch (typeof slide.style) {
      case "string":
        if (Object.keys(JSON.parse(slide.style)).includes("backgroundColor"))
          return JSON.parse(slide.style);
        else return defaultColor;
      case "object":
        if (slide.style && Object.keys(slide.style).includes("backgroundColor"))
          return slide.style;
        else return defaultColor;
      default:
        return defaultColor;
    }
  });

  useEffect(() => {
    setBounds({
      boundingWidth:
        slideRef.current?.getBoundingClientRect().width || initialPixelWidth,
      boundingHeight: slideRef.current?.getBoundingClientRect().height || 100,
    });
  }, [useMemo(() => slideRef.current?.getBoundingClientRect().width, [width])]);

  useEffect(() => {
    if (slide.style) slide.style = backgroundColor;
  }, [slide.tiles[0], backgroundColor]);

  const slideRouter = () => {
    switch (slide.layoutType) {
      case FULLSCREEN_FEATURES.IMAGE:
        return <SlideFullFeaturedImage tile={slide.tiles[0] as TileModel} />;
      case FULLSCREEN_FEATURES.TEXT:
        return <SlideFullFeaturedText tile={slide.tiles[0] as TileModel} />;
      case MULTI_TILE_DASHBOARD.NINE_TILE_DASHBOARD:
        return <SlideNineTileDashboard tiles={slide.tiles} />;
      default:
        return <>This tile type is not supported yet!</>;
    }
  };

  return (
    <Box
      component="div"
      onClick={onClick}
      sx={{ width: bounds.boundingWidth, height: bounds.boundingHeight, ...sx }}
    >
      <Box
        ref={slideRef}
        className={["shadow-lg py-[3rem] px-[5rem]"].join(" ")}
        sx={{
          aspectRatio: `${SLIDE_ASPECT_RATIO} !important`,
          width: `${initialPixelWidth}px`,
          transform: `scale(${width ? width / initialPixelWidth : 1})`,
          transformOrigin: "top left",
          backgroundColor,
        }}
      >
        {slideRouter()}
      </Box>
    </Box>
  );
};

export default observer(Slide);
