import { ThemeOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "cfa-white": true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#DD0031",
    },
    secondary: {
      main: "#004F71",
    },
  },
  breakpoints: {
    values: {
      xs: 376,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "cfa-white" },
          style: {
            backgroundColor: "#FFFFFF",
            outline: "1px solid #3EB1C8",
            fontWeight: "bold",
            textTransform: "inherit",
          },
        },
      ],
    },
  },
} as ThemeOptions);

export default theme;
