import { Box } from "@mui/material";
import {
  TileArchetypeView,
  TileArchetypeCenterContent,
} from "../../../Archetype/Archetype";

interface CelebrationProps {
  celebrationLabel: string;
  name: string;
  date: string;
  celebrationType: string;
  displayImage: boolean;
  imageUrl: string;
}

const renderBackdrop = (celebrationType: string) => {
  switch (celebrationType) {
    case "BIRTHDAY":
      return (
        <>
          <Box
            component="img"
            src="/celebrations/cake.svg"
            className="absolute scale-[50%] -right-3"
            sx={{ bottom: "-40px" }}
          />
        </>
      );
    case "ANNIVERSARY":
      return (
        <>
          <Box
            component="img"
            src="/celebrations/mirrorball.svg"
            className="absolute scale-[100%] top-0"
            sx={{ right: "-0.25rem" }}
          />
        </>
      );
    default:
      return <></>;
  }
};

const CelebrationView = ({
  celebrationLabel,
  name,
  date,
  celebrationType,
  displayImage,
  imageUrl,
}: CelebrationProps) => {
  return (
    <TileArchetypeView label="">
      <TileArchetypeCenterContent className="gap-4 pt-[26px]">
        {renderBackdrop(celebrationType)}
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          {displayImage ? (
            <>
              <Box
                component="img"
                src={imageUrl}
                className="h-[4rem] w-[4rem] rounded-full"
              />
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box className="relative flex flex-col right-1 gap-0 items-left text-left">
          <Box className="font-apercu font-extrabold text-[1rem] text-left">
            {celebrationLabel}
          </Box>
          <Box className="font-apercu font-bold text-[0.9rem] text-left">
            {name}
          </Box>
          <Box className="font-apercu font-medium text-[0.8rem] text-left">
            {date}
          </Box>
        </Box>
      </TileArchetypeCenterContent>
    </TileArchetypeView>
  );
};
export default CelebrationView;
