import { Box, SxProps, Typography } from "@mui/material";
import Carousel, { CarouselProps } from "../../Carousel/Carousel";
import { ReactNode } from "react";
import { t } from "i18next";

export const TileArchetypeCenterContent = ({
  children,
  className,
  sx,
}: {
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
}) => {
  return (
    <Box
      className={`relative w-full h-full flex justify-center items-center -top-3 ${className}`}
      sx={{ ...sx }}
    >
      {children}
    </Box>
  );
};

export const TileArchetypeLabel = ({
  children,
  className,
  sx,
}: {
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
}) => {
  return (
    <Box
      className={`absolute w-full ml-[29px] text-secondary text-left ${className}`}
      sx={{ ...sx }}
    >
      <Typography fontSize="10px">{children}</Typography>
    </Box>
  );
};

export const TileArchetypeView = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <Box className="w-full h-full">
      <TileArchetypeLabel>{label}</TileArchetypeLabel>
      <Box className="w-full h-full pt-[15px]">{children}</Box>
    </Box>
  );
};

interface TileArchetypeProps {
  startAdornment?: string;
  title: string;
  endAdornment?: string;
  children: React.ReactNode;
  className?: string;
  carouselProps?: CarouselProps;
  error?: boolean;
  noPadding?: boolean;
}
const TileArchetype = ({
  title,
  children,
  className,
  startAdornment,
  endAdornment,
  carouselProps,
  error,
  noPadding,
}: TileArchetypeProps) => {
  return (
    <Box
      className={`TileArchetype relative h-full w-full bg-white ${className}`}
    >
      <Box className="absolute w-full h-full">
        <Box className="w-full h-fit px-3 flex justify-between">
          <Box className="p-2 flex gap-1 items-center align-middle">
            {startAdornment && (
              <Box
                component="img"
                className="justify-center"
                src={startAdornment}
                sx={{ width: "17px" }}
              />
            )}
            <Box
              className="uppercase text-secondary whitespace-nowrap truncate-text"
              sx={{
                fontFamily: "Caecilia LT Std",
                fontSize: "14px",
                fontWeight: "800",
                lineHeight: "17px",
                letterSpacing: "3px",
              }}
            >
              {title}
            </Box>
          </Box>
          {endAdornment && (
            <Box className="text-secondary whitespace-nowrap pt-2 truncate-text">
              <Typography fontSize="12px" noWrap>
                {endAdornment}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        className="absolute w-full h-full px-3"
        sx={{
          paddingTop: noPadding ? "0" : "26px",
        }}
      >
        {error ? (
          <Box className="relative -top-1 h-full flex flex-col gap-2 justify-center items-center">
            <Box component="img" src="/error.svg" />
            <Typography variant="caption">{t("errors.tile")}</Typography>
          </Box>
        ) : (
          <>
            {Array.isArray(children) && children?.length > 1 ? (
              <Carousel {...carouselProps}>{children.filter(Boolean)}</Carousel>
            ) : (
              children
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TileArchetype;
