import { Box } from "@mui/material";
import { Tile as ITile } from "../../../models";
import { Tile } from "../../_Tile";

interface SlideFullFeaturedTextProps {
  tile?: ITile;
}
const SlideFullFeaturedText = ({ tile }: SlideFullFeaturedTextProps) => {
  return (
    <Box className="full">
      <Tile tile={tile as ITile} />
    </Box>
  );
};

export default SlideFullFeaturedText;
