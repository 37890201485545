import "./App.scss";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import {
  Box,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { HomePage, SlidePage } from "./pages";
import { SecureRoute } from "./components";
import CombinedStore, { store } from "./stores/index";
import theme from "./utils/mui.theme";

const App = () => {
  return (
    <Box component="div" className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CombinedStore.Provider value={store}>
            <Router>
              <Routes>
                <Route path="/" element={<SecureRoute />}>
                  <Route path="" element={<Navigate to="home" />} index />
                  <Route path="home" element={<HomePage />} />
                  <Route path="slides">
                    <Route path=":id" element={<SlidePage />} />
                  </Route>
                </Route>
                <Route path="unsecured" element={<h1>Unsecured</h1>} />
              </Routes>
            </Router>
          </CombinedStore.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Box>
  );
};

export default App;
