import { createContext } from "react";
import User from "./user.store";

interface Store {
  user: User;
}

export const store: Store = {
  user: new User(),
  // Add more stores here...
};

export default createContext(store);
