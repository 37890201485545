import { Box } from "@mui/material";
import { t } from "i18next";
import { SalesActivityTile as ISalesActivityTile } from "../../../models";
import Archetype from "../Archetype/Archetype";
import { SalesView } from "./_components";

interface SalesActivityTileProps {
  tile: ISalesActivityTile;
}
const SalesActivityTile = ({ tile: { context } }: SalesActivityTileProps) => {
  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.SalesActivity.title")}
        startAdornment={"/sales-activity.svg"}
      >
        {/* Slide 1 */}
        <SalesView
          label={t("tiles.SalesActivity.sales.label")}
          icon={"/money.svg"}
          quantity={context.sales?.totalAmount}
          chip={{
            label: `${context.sales?.changePercent} ${t(
              "tiles.SalesActivity.sales.duration",
            )}`,
            direction: context.sales?.changePercent.startsWith("+") ?? false,
          }}
        />

        {/* Slide 2 */}
        <SalesView
          label={t("tiles.SalesActivity.transactions.label")}
          icon={"/carry-out.svg"}
          quantity={context.transactions?.totalAmount}
          chip={{
            label: `${context.transactions?.changePercent} ${t(
              "tiles.SalesActivity.transactions.duration",
            )}`,
            direction:
              context.transactions?.changePercent.startsWith("+") ?? false,
          }}
        />

        {/* Slide 3 */}
        <SalesView
          label={t("tiles.SalesActivity.checkAverages.label")}
          icon={"/check-average.svg"}
          quantity={context.checkAverages?.totalAmount}
          chip={{
            label: `${context.checkAverages?.changePercent} ${t(
              "tiles.SalesActivity.checkAverages.duration",
            )}`,
            direction:
              context.checkAverages?.changePercent.startsWith("+") ?? false,
          }}
        />
      </Archetype>
    </Box>
  );
};

export default SalesActivityTile;
