import { Box } from "@mui/material";
import { t } from "i18next";
import { LaborProductivityTile as ILaborProductivityTile } from "../../../models";
import Archetype from "../Archetype/Archetype";
import { ProductivityView } from "./_components";

interface LaborProductivityTileProps {
  tile: ILaborProductivityTile;
}
const LaborProductivityTile = ({
  tile: { context },
}: LaborProductivityTileProps) => {
  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.LaborProductivity.title")}
        startAdornment={"/labor-productivity.svg"}
      >
        {/* Labor Productivity currently only has one rotation */}
        <ProductivityView
          label={t("tiles.LaborProductivity.label")}
          productivity={context.productivity}
          percentageOfSales={context.percentSales}
        />
      </Archetype>
    </Box>
  );
};

export default LaborProductivityTile;
