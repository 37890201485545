import axios from "axios";
import { getToken } from "./common";

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    try {
      const token = getToken();
      console.assert(token, "No token found!");
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error(error);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default axios;
