const HomePage = () => {
  return (
    <header className="App-header">
      <img
        src={"https://media.giphy.com/media/R7shrs2v8hsc5WGXVT/giphy.gif"}
        className="App-logo"
        alt="logo"
      />
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Eat Mor Chikin
      </a>
    </header>
  );
};

export default HomePage;
