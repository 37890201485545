import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper, { MobileStepperProps } from "@mui/material/MobileStepper";
import TextStepper from "../TextStepper/TextStepper";
import { SxProps } from "@mui/material/styles";
import { ReactNode, useState } from "react";
import SwipeableViews, {
  AxisType,
  OnChangeIndexCallback,
  SwipeableViewsProps,
} from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface CarouselProps {
  axis?: AxisType;
  children?: ReactNode[];
  className?: string;
  backButton?: ReactNode;
  interval?: number;
  nextButton?: ReactNode;
  onStepChange?: OnChangeIndexCallback;
  mobileStepperProps?: Partial<MobileStepperProps>;
  swippableProps?: Partial<SwipeableViewsProps>;
  sx?: SxProps;
  useTextStepper?: boolean;
}
const Carousel = ({
  axis,
  children,
  className,
  backButton,
  interval,
  nextButton,
  onStepChange,
  mobileStepperProps,
  swippableProps,
  sx,
  useTextStepper,
}: CarouselProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = children?.length || 0;

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleStepChange(step: number, indexLatest: number) {
    setActiveStep(step);
    onStepChange?.(step, indexLatest);
  }

  return (
    <Box
      className={`Carousel relative ${className || ""}`}
      sx={{
        height: "100%",
        flexGrow: 1,
        "& > div": {
          position: "absolute",
          width: "100%",
          background: "transparent",
        },
        "& > div:first-child": { height: "100%" },
        "& .react-swipeable-view-container > div": {
          height: "100%",
        },
        ...sx,
      }}
    >
      <AutoPlaySwipeableViews
        axis={axis || "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={interval || 30000 / (children?.length || 1)}
        enableMouseEvents
        slideStyle={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
        containerStyle={{ height: "100%" }}
        {...swippableProps}
      >
        {(children || [<></>])?.map((child, index) => (
          <Box
            key={`carousel-${index}`}
            id={`carousel-${index}`}
            sx={{ height: "100%" }}
          >
            {child}
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      {useTextStepper ? (
        <TextStepper steps={maxSteps} activeStep={activeStep} sx={{}} />
      ) : (
        <MobileStepper
          className="carousel-stepper"
          steps={maxSteps}
          activeStep={activeStep}
          position="static"
          nextButton={
            <Box>
              {nextButton && (
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {nextButton}
                </Button>
              )}
            </Box>
          }
          backButton={
            <Box>
              {backButton && (
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {backButton}
                </Button>
              )}
            </Box>
          }
          sx={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            height: "24px",
            "& .MuiMobileStepper-dots": {
              gap: "4px",
            },
            "& .MuiMobileStepper-dotActive": {
              backgroundColor: "#004F71",
            },
          }}
          {...mobileStepperProps}
        />
      )}
    </Box>
  );
};

export default Carousel;
