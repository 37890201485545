import { Box } from "@mui/material";
import {
  Editor,
  EditorState,
  RawDraftContentState,
  convertFromRaw,
} from "draft-js";
import { FC, useEffect, useState } from "react";

import { CustomStyleMap, getEditorContents } from "../../models";
import "./RichTextEditor.scss";

interface RichTextEditorProps {
  defaultValue?: string;
  rawContentState: RawDraftContentState;
  placeholder?: string;
  controlGroups?: Array<string>;
  selectable?: boolean;
  onChange?: (editorContent: RawDraftContentState) => void;
  border?: boolean;
  noWrap?: boolean;
}
const RichTextEditor: FC<RichTextEditorProps> = ({
  rawContentState,
  placeholder,
  onChange,
  border,
  noWrap,
}) => {
  const [editorState] = useState(() => {
    try {
      const contentState = convertFromRaw(rawContentState);
      return EditorState.createWithContent(contentState);
    } catch (error) {
      console.error("RichTextEditor:useState:rawContentState", error);
      const contentState = convertFromRaw({
        blocks: [],
        entityMap: {},
      });
      return EditorState.createWithContent(contentState);
    }
  });

  function changeHandler(editorState: EditorState) {
    console.log("editorstate", editorState);
  }

  useEffect(() => {
    const editorContent = getEditorContents(editorState);
    onChange?.(editorContent);
  }, [editorState]);

  return (
    <Box
      component="div"
      className={[`full relative flex flex-col space-around`].join(" ")}
      sx={{
        border: border ? "2px dashed rgba(0, 0, 0, .3)" : "",
        "& .public-DraftStyleDefault-block": {
          whiteSpace: noWrap ? "nowrap !important" : "",
          overflow: noWrap ? "hidden" : "",
        },
      }}
    >
      <Editor
        onChange={changeHandler}
        placeholder={placeholder}
        editorState={editorState}
        customStyleMap={CustomStyleMap}
        readOnly={true}
      />
    </Box>
  );
};
export default RichTextEditor;
