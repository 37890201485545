import {
  AmplitudeAction,
  AmplitudeDomain,
  DraggableType,
  Filters,
  LanguageCode,
  ObjectType,
} from ".";

export const MAX_PAGE_SIZE = 100;

export const LanguageCodes: { [key: string]: LanguageCode } = {
  EN: "en",
};

export const ObjectTypes: { [key: string]: ObjectType } = {
  PLAYLIST: "PLAYLIST",
  SLIDE: "SLIDE",
  TILE: "TILE",
  IMAGE: "IMAGE",
};

export const DraggableTypes: { [key: string]: DraggableType } = {
  CONTENT_PREVIEW: "CONTENT_PREVIEW",
  CONTENT_PREVIEW_BOTTOM_SORTABLE: "CONTENT_PREVIEW_BOTTOM_SORTABLE",
  SLIDE_EDITOR_DATA_TILE: "SLIDE_EDITOR_DATA_TILE",
  SLIDE_EDITOR_IMAGE_TILE: "SLIDE_EDITOR_IMAGE_TILE",
};

export const AmplitudeDomains: { [key: string]: AmplitudeDomain } = {
  SLIDES: ObjectTypes.SLIDE,
  LOCATIONS: ObjectTypes.LOCATION,
  PLAYLISTS: ObjectTypes.PLAYLIST,
  TILES: ObjectTypes.TILE,
};

export const AmplitudeActions: { [key: string]: AmplitudeAction } = {
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
};

export function filterSubject<T>(
  haystack: Array<T>,
  filters: Filters<T>,
): Array<T> {
  let output: T[] = [...haystack];
  for (const key of Object.keys(filters)) {
    output = filters[key](output);
  }
  return output;
}

export function addFilterSubject<T>(
  name: string,
  handler: (haystack: Array<T>) => Array<T>,
  collection: Filters<T>,
) {
  Object.assign(collection, { [name]: handler });
  return collection;
}

export function removeFilterSubject<T>(name: string, collection: Filters<T>) {
  delete collection[name];
  return collection;
}

export const createHistoriedItem = () => {
  return {
    date: new Date().toISOString(),
    user: ``,
  };
};
