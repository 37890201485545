import { Box } from "@mui/material";
import { CelebrationsTile as ICelebrationsTile } from "../../../models";
import Archetype from "../Archetype/Archetype";
import { CelebrationView } from "./_components";
import { parse } from "date-fns/parse";
import { format } from "date-fns/format";
import { isValid } from "date-fns/isValid";
import { t } from "i18next";

interface CelebrationsTileProps {
  tile: ICelebrationsTile;
}

const parseDate = (date: string) => {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());

  if (isValid(parsedDate)) {
    return format(parsedDate, "EEEE, LLL d");
  }
  return "";
};

const getI18NLabel = (celebrationType: string) => {
  switch (celebrationType) {
    case "BIRTHDAY":
      return "tiles.Celebrations.message.birthday";
    case "ANNIVERSARY":
      return "tiles.Celebrations.message.anniversary";
    default:
      return "tiles.Celebrations.message.congratulations";
  }
};

const CelebrationsTile = ({ tile: { context } }: CelebrationsTileProps) => {
  return (
    <Box className="absolute w-full h-full">
      <Archetype
        title={t("tiles.Celebrations.title")}
        carouselProps={{ useTextStepper: true }}
        startAdornment={"/celebrations/icon.svg"}
        noPadding
      >
        {context.celebrationList.map((celebration, index) => (
          <CelebrationView
            key={index}
            celebrationLabel={t(getI18NLabel(celebration.celebrationType))}
            celebrationType={celebration.celebrationType}
            name={celebration.person.formattedFullName}
            date={parseDate(celebration.celebrationDate)}
            displayImage={celebration.person.imageExists}
            imageUrl={celebration.person.imageUrl}
          />
        ))}
      </Archetype>
    </Box>
  );
};

export default CelebrationsTile;
