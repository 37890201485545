import { Box, Divider } from "@mui/material";
import {
  TileArchetypeView,
  TileArchetypeCenterContent,
} from "../../../Archetype/Archetype";
import { t } from "i18next";

interface CurrentHourProps {
  label: string;
  type: string;
  totalSales: string;
  transactions: string;
  checkAverage: string;
}

const renderBackdrop = (type: string) => {
  switch (type) {
    case "DRIVE_THRU":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/driveThru.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    case "DINE_IN":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/dineIn.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    case "TO_GO":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/carryOut.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    case "CATERING":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/catering.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    case "ON_DEMAND":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/onDemand.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    case "CURBSIDE":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/curbside.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    case "FULL_SERVICE":
      return (
        <>
          <Box
            component="img"
            src="/current_hour/fullService.svg"
            className="absolute h-[4rem] left-2"
            sx={{ bottom: "-12px" }}
          />
        </>
      );
    default:
      return <></>;
  }
};

const CurrentHourView = ({
  label,
  type,
  totalSales,
  transactions,
  checkAverage,
}: CurrentHourProps) => {
  return (
    <TileArchetypeView label={label}>
      <TileArchetypeCenterContent className="gap-4">
        {renderBackdrop(type)}
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box className="flex text-[0.75rem] align-middle items-center gap-1">
            <Box
              component="img"
              src="/current_hour/sales.svg"
              className="h-[0.90rem]"
            />
            {t("tiles.CurrentHourSalesActivity.totalSales")}
          </Box>
          <Box
            className="font-apercu font-bold text-[2rem] text-left"
            sx={{ lineHeight: "38px" }}
          >
            {totalSales}
          </Box>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box className="relative flex flex-col right-1 gap-1">
            <Box className="flex text-[0.75rem] align-middle items-center gap-1">
              <Box
                component="img"
                src="/current_hour/transactions.svg"
                className="h-[0.90rem]"
              />
              {t("tiles.CurrentHourSalesActivity.transactions")}
            </Box>
            <Box
              className="font-apercu font-bold text-[1.25rem] text-center"
              sx={{ lineHeight: "1.25rem" }}
            >
              {transactions}
            </Box>
            <Divider orientation="horizontal" flexItem />
            <Box className="flex text-[0.75rem] align-middle items-center gap-1">
              <Box
                component="img"
                src="/current_hour/checkAvg.svg"
                className="h-[0.90rem]"
              />
              {t("tiles.CurrentHourSalesActivity.checkAvg")}
            </Box>
            <Box
              className="font-apercu font-bold text-[1.25rem] text-center"
              sx={{ lineHeight: "1.25rem" }}
            >
              {checkAverage}
            </Box>
          </Box>
        </Box>
        {/* <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box
            className="font-apercu font-extrabold text-[32px] text-left"
            sx={{ lineHeight: "40px" }}
          >
            {time}
          </Box>
          <Box
            className="font-apercu font-medium text-[11px] text-left"
            sx={{ lineHeight: "12px" }}
          >
            {timeLabel}
          </Box>
        </Box>
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box
            className="font-apercu font-extrabold text-[32px] text-left"
            sx={{ lineHeight: "40px" }}
          >
            {count}
          </Box>
          <Box
            className="font-apercu font-medium text-[11px] text-left"
            sx={{ lineHeight: "12px" }}
          >
            {countLabel}
          </Box>
        </Box> */}
      </TileArchetypeCenterContent>
    </TileArchetypeView>
  );
};
export default CurrentHourView;
