import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  DataTile,
  Tile as ITile,
  ImageTile,
  TextTile,
  TileTypes,
} from "../../../models";
import TileDataLayout from "../TileDataLayout/TileDataLayout";
import TileTextLayout from "../TileTextLayout/TileTextLayout";
import { TileImageLayout } from "..";

interface TileProps {
  tile: ITile;
  editing?: boolean;
  onTileChange?: (tile: ITile) => void;
}
const Tile: FC<TileProps> = ({ tile, onTileChange }) => {
  const [context] = useState<typeof tile.context>(
    tile?.context || { style: "" },
  );

  useEffect(() => {
    if (tile?.context) tile.context = context;
    onTileChange?.(tile);
  }, [context]);

  const TileMap = () => {
    switch (tile?.tileType) {
      case TileTypes.IMAGE:
        return <TileImageLayout image={(tile as ImageTile)?.src} />;
      case TileTypes.TEXT:
        return <TileTextLayout context={context as TextTile["context"]} />;
      case TileTypes.DATA:
        return <TileDataLayout tile={tile as ITile & DataTile} />;
      default:
        console.warn(`No component found for tile type ${tile?.tileType}`);
        return <></>;
    }
  };

  return (
    <Box
      tabIndex={0}
      className="Tile w-full h-full flex flex-col justify-center"
      sx={{
        //Scale tile to fit the container
        backgroundColor: "transparent",
        borderRadius: "5px",
        // ...tile?.context?.style,
      }}
    >
      {TileMap()}
    </Box>
  );
};

export default Tile;
