import { Box } from "@mui/material";
import {
  TileArchetypeView,
  TileArchetypeCenterContent,
} from "../../../Archetype/Archetype";

interface SpeedOfServiceProps {
  label: string;
  time: string;
  timeLabel: string;
  count: string;
  countLabel: string;
}
const SpeedOfServiceView = ({
  label,
  time,
  timeLabel,
  count,
  countLabel,
}: SpeedOfServiceProps) => {
  return (
    <TileArchetypeView label={label}>
      <TileArchetypeCenterContent className="gap-4">
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box
            className="font-apercu font-extrabold text-[32px] text-left"
            sx={{ lineHeight: "40px" }}
          >
            {time}
          </Box>
          <Box
            className="font-apercu font-medium text-[11px] text-left"
            sx={{ lineHeight: "12px" }}
          >
            {timeLabel}
          </Box>
        </Box>
        <Box className="relative flex flex-col right-1 gap-0 items-center">
          <Box
            className="font-apercu font-extrabold text-[32px] text-left"
            sx={{ lineHeight: "40px" }}
          >
            {count}
          </Box>
          <Box
            className="font-apercu font-medium text-[11px] text-left"
            sx={{ lineHeight: "12px" }}
          >
            {countLabel}
          </Box>
        </Box>
      </TileArchetypeCenterContent>
    </TileArchetypeView>
  );
};
export default SpeedOfServiceView;
