import { Box, SxProps } from "@mui/material";
import { DataTileTypes } from "../../../models";

// TODO: When these tiles are further developed, the keys should all be of type DATA_TILE_TYPE
const typeMap: { [key: string]: unknown } = {
  aha: "aha_performance.svg",
  [DataTileTypes.CATERING]: "catering.svg",
  [DataTileTypes.CELEBRATIONS]: "celebrations.svg",
  [DataTileTypes.CEM_COMMENTS]: "cem_comments.svg",
  [DataTileTypes.CEM_KEY_MEASURES]: "cem.svg",
  cfa_delivery: "cfa_delivery.svg",
  [DataTileTypes.CURRENT_HOUR_SALES_ACTIVITY]: "current_hour.svg",
  custom_message: "custom_message.svg",
  dining_options: "dining_options.svg",
  erqa: "erqa.svg",
  [DataTileTypes.HOURLY]: "hourly_sales.svg",
  labor_scheduling: "labor_scheduling.svg",
  [DataTileTypes.LABOR_PRODUCTIVITY]: "labor.svg",
  [DataTileTypes.MTD_SALES]: "mtd_sales.svg",
  new_hires: "new_hires.svg",
  safe_daily_critical: "safe_daily_critical.svg",
  [DataTileTypes.SALES_ACTIVITY]: "sales_activity.svg",
  [DataTileTypes.SPEED_OF_SERVICE]: "speed_of_service.svg",
  weather: "weather.svg",
};

interface FacsimileProps {
  type: keyof typeof typeMap;
  className?: string;
  sx?: SxProps;
}
const Facsimile = ({ type, className, sx }: FacsimileProps) => {
  return (
    <Box className={className} sx={{ ...sx }}>
      <img
        src={`/${typeMap[type]}`}
        alt={`${typeMap[type] || "data-tile-img"}`}
      />
    </Box>
  );
};

export default Facsimile;
